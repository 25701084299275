import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ComponentLinks, FigmaEmbed } from '../common';
import { Playground, Props } from 'docz';
import { useState } from 'react';
import { SingleTimePicker } from '@hse-design/react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "singletimepicker"
    }}>{`SingleTimePicker`}</h1>
    <ComponentLinks storybook={'/?path=/story/calendar-calendar--single-time-picker'} figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=8162%3A1143'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`SingleTimePicker – компонент для выбора времени из списка возможных вариантов.`}</p>
    <p>{`SingleTimePicker контролируемый компонент: для его работы следует передать пропы `}<inlineCode parentName="p">{`value`}</inlineCode>{` и `}<inlineCode parentName="p">{`onChange`}</inlineCode>{`.`}</p>
    <p>{`Диапазон времени задаётся пропами `}<inlineCode parentName="p">{`from`}</inlineCode>{` и `}<inlineCode parentName="p">{`to`}</inlineCode>{`. Шаг минут задаётся пропом `}<inlineCode parentName="p">{`step`}</inlineCode>{`.`}</p>
    <Playground __position={1} __code={'() => {\n  const [value, setValue] = useState(\'12:00\')\n  return (\n    <div\n      style={{\n        width: \'100%\',\n        height: 150,\n        display: \'flex\',\n        justifyContent: \'center\',\n      }}\n    >\n      <SingleTimePicker\n        from={\'00:00\'}\n        to={\'23:30\'}\n        step={30}\n        value={value}\n        onChange={setValue}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      ComponentLinks,
      FigmaEmbed,
      Playground,
      Props,
      useState,
      SingleTimePicker,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
      {() => {
        const [value, setValue] = useState('12:00');
        return <div style={{
          width: '100%',
          height: 150,
          display: 'flex',
          justifyContent: 'center'
        }}>
        <SingleTimePicker from={'00:00'} to={'23:30'} step={30} value={value} onChange={setValue} mdxType="SingleTimePicker" />
      </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "поведение"
    }}>{`Поведение`}</h2>
    <FigmaEmbed node='?node-id=9288%3A5708' height={1600} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node='?node-id=9288%3A5630' height={1600} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node='?node-id=9288%3A5736' mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={SingleTimePicker} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      